<template>
  <h1></h1>
</template>

<script>
export default {
  name: 'AuthUser',
  mounted () {
    this.getSignInToken()
  },
  methods: {
    getSignInToken () {
      this.$store.dispatch('auth/getSignInToken')
        .then((response) => {
          const url = response.data.data.linkToAuthPage
          window.location.replace(url)
        })
        .catch()
    }
  }
}
</script>

<style scoped>

</style>
